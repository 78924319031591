body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#\31 234 > div > div > div > div {
  margin: 0;
}

.ant-form-item{
  margin-bottom: 15px;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.yolup > div > div.ant-picker-panel-container.ant-picker-date-panel-container > div > div > div {
  width: min-content;
  flex-wrap: wrap;
}

.blur-background {
  position: relative;
}

.blur-background::after {
  content: '';
  position: absolute;
  border-radius: 8px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(.2px);
  background: rgba(255, 255, 255, 0.1);
}

.ant-modal-close {
  top: 6px !important;
}
